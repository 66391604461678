import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { TariffPlanCard } from "@ui/components";

import { getTariffPlansQueryOptions } from "@core/apis/queries";

const TarrifPlansPage = () => {
  const { data: tariffPlanData, isLoading: tariffPlanLoading } = useQuery(
    getTariffPlansQueryOptions()
  );

  return (
    <>
      <Typography variant="h2">Tariff Plans</Typography>
      {tariffPlanLoading && <p>Loading...</p>}
      <Box display="flex" flexWrap="wrap" gap={1} justifyContent="stretch">
        {tariffPlanData &&
          tariffPlanData.map((plan) => (
            <TariffPlanCard plan={plan}>
              <Button size="small" component={Link} to={`/app/tariff-plans/${plan.uid}`}>
                View
              </Button>
            </TariffPlanCard>
          ))}
      </Box>
    </>
  );
};

export const Route = createFileRoute("/app/_layout/tariff-plans/")({
  component: TarrifPlansPage,
  loader: async (opts) => {
    // Fancy little piece of code that ensures the networks data is loaded before rendering the component
    const data = await opts.context.queryClient.ensureQueryData(getTariffPlansQueryOptions());
    return {
      data,
    };
  },
});
